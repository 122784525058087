<script>
import {defineComponent} from 'vue'
import router from "@/router";

export default defineComponent({
  name: "CatalogCol",
  props: {
    items: Array,
  },
  methods: {
    router() {
      return router
    },
    push(item) {
      this.$router.push({name: `product`, params: {name: item.id, product: item}})
    }
  },
})
</script>

<template>
  <v-card rounded="0" style="margin-bottom: 10px;">
    <v-card rounded="5" color="none">
      <v-card-title class="catalog-title"
                    style="font-size: 28px; border-left: #2196F3 4px solid; cursor: pointer;white-space: normal;"
                    @click="router().push({ path: 'products' })">Каталог
      </v-card-title>
      <hr/>
      <v-card :key="item" v-for="(item) in items" rounded="2" color="white"
              style="margin-top: 10px; border-left: #2196F3 2px solid">
        <v-card-title class="box">
          <p style="font-size: 0.8em;  cursor: pointer;white-space: normal;" @click="this.push(item)">{{ item.name }}
          </p>
        </v-card-title>
      </v-card>

    </v-card>
  </v-card>
</template>

<style scoped>
.box {
  transition: background-color 0.1s ease-in-out;
}

.box:hover {
  background-color: #e4e4e4;
  cursor: pointer;
}

.catalog-title:hover {
  background-color: #e4e4e4;
}

.catalog-title {
  transition: background-color 0.1s ease-in-out;
}
</style>
<template>
  <div>
    <v-card rounded="0">
      <v-card rounded="0" style="margin-bottom: 1px">
        <v-card-title class="contacts-card"  @click=" this.$router.push('contacts')" style="cursor: pointer;font-size: 28px;border-left: #2196F3 4px solid">Контакты</v-card-title>
      </v-card>
      <v-list style="margin-top: 1px; color: black;">
        <v-list-item
            style="color: black;"
            prepend-icon="mdi-phone"
            title="+7 (3513) 25-72-83"
        ></v-list-item>

        <v-divider color="black"></v-divider>


        <v-list-item
            style=" color: black;"
            prepend-icon="mdi-email"
        >
          <v-list-item-action>
            <a style="text-decoration: none" href="mailto:info@nppse.com"> <p style="color: black">info@nppse.com</p>  </a>
          </v-list-item-action>
        </v-list-item>


        <v-divider color="black"></v-divider>

        <v-list-item prepend-icon="mdi-map-marker" style=" color: black;">

          456300, Челябинская область, город Миасс, ул. Объездная дорога, дом 2/10, офис 203

        </v-list-item>
      </v-list>
    </v-card>
  </div>

</template>

<style scoped>
.contacts-card{
  transition: background-color 0.1s ease-in-out;
}
.contacts-card:hover {
  background-color: #e4e4e4;
}
</style>
<script setup>
</script>
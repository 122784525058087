<template>
  <v-toolbar
      style="padding: 35px;"
      color="blue-darken-4">
    <v-container>
      <v-row class="hidden-sm-and-down">
        <v-col lg="8" md="8" sm="9" style="margin-left: 12px">
          <v-row>
            <a :href="'/'" style="text-decoration: none">
              <v-img alt="Логотип спецэл" style="margin-top: 10px" :href="'/'" width="120px" src="../assets/logo.svg">
              </v-img>
            </a>
            <div style="margin-top: 25px;margin-left: 5px">
              <a :href="'/'" style="text-decoration: none;  font-weight: bold">
                <p style="font-size: 30px; ">
                  ООО «СпецЭл»
                </p>
                <p style="font-size: 16px;">
                  Разрабатываем и производим электронику с 2011 года
                </p>
              </a>
            </div>
          </v-row>
        </v-col>

        <v-col style="margin-top: 5px" class="text-right font-weight-light">
          <p style="font-size: 25px">
            <a href="mailto:info@nppse.com">
              info@nppse.com
            </a>
          </p>

          <p>
            <v-icon icon="mdi-phone"></v-icon>
            +7 (3513) 25-72-83
          </p>

          <p style="font-size: 15px">
            Контакты для связи
          </p>
        </v-col>
      </v-row>

      <v-col class="hidden-md-and-up"  style="text-align: -webkit-center; justify-items: center">
        <a :href="'/'" style="text-decoration: none">
          <v-img :href="'/'" width="80px" max-width="100px" src="../assets/logo.svg">
          </v-img>
        </a>
        <a :href="'/'" style="text-decoration: none">
          <strong style="font-size: 24px; ">
            ООО «СПЕЦЭЛ»
          </strong>
        </a>

      </v-col>
    </v-container>
  </v-toolbar>
</template>

<script setup>
</script>

<style scoped>
.header-text {
  font-weight: bold;
  font-family: Robot, sans-serif;;
  font-size: 32px;
}

a:active,
a:hover,
a {
  text-decoration: none;
  color: white;
}
</style>

import {createRouter, createWebHistory} from 'vue-router'
import MainView from "@/views/MainView.vue";
import ContactsView from "@/views/ContactsView.vue";
import DeliveryView from "@/views/DeliveryView.vue";
import AboutUsView from "@/views/AboutUsView.vue";
import ProductsView from "@/views/ProductsView.vue";
import WareHouseView from "@/views/WareHouseView.vue";
import LabourProtectionView from "@/views/LabourProtectionView.vue";
import ProductView from "@/views/ProductView.vue";


const routes = [
    {
        path: '/',
        name: 'home',
        component: MainView,
        meta: {title: 'Главная'}
    },
    {
        name: 'product',
        path: '/product/:name',
        component: ProductView
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: ContactsView,
        meta: {title: 'Контакты'}
    },
    {
        path: '/delivery',
        name: 'delivery',
        component: DeliveryView,
        meta: {title: 'Доставка'}
    },
    {
        path: '/about-us',
        name: 'about',
        component: AboutUsView,
        meta: {title: 'О нас'}
    },
    {
        path: '/products',
        name: 'products',
        component: ProductsView,
        meta: {title: 'Продукция'}
    },
    {
        path: '/warehouse',
        name: 'warehouse',
        component: WareHouseView,
        meta: {title: 'Наличие на складе'}
    },
    {
        path: '/labour-protection',
        name: 'labour-protection',
        component: LabourProtectionView,
        meta: {title: 'Охрана труда'}
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    mode: 'hash',
    routes
})

export default router

<template>
  <v-layout>
    <v-footer
        color="blue-darken-4"
    >
      <v-container>
        <!--        <v-row>-->
        <!--          <v-col>-->
        <!--            <v-btn to="/" roыunded="0">Главная</v-btn>-->
        <!--            <v-btn to="/products" rounded="0">Каталог товаров</v-btn>-->
        <!--            <v-btn to="/warehouse" rounded="0">Наличие на складе</v-btn>-->
        <!--            <v-btn to="/delivery" rounded="0">Доставка и оплата</v-btn>-->
        <!--            <v-btn to="/contacts" rounded="0">Контакты</v-btn>-->
        <!--            <v-btn to="/about-us" rounded="0">О нас</v-btn>-->
        <!--          </v-col>-->
        <!--          <v-col></v-col>-->
        <!--        </v-row>-->
        <div class="text-center">
          2011-{{ new Date().getFullYear() }} — <strong>ООО «СПЕЦЭЛ»</strong>
        </div>
      </v-container>
    </v-footer>
  </v-layout>
</template>

<style scoped>

</style>
<script setup>
</script>
<template>
  <div style="background-color: #eeeeee">
    <v-container>
      <v-row>
        <v-col cols="12" lg="9" xs="12">
          <v-list style="border-left: #2196F3 4px solid; margin-bottom: 10px;">
            <v-list-item>
              <v-list-item-title style="font-size: 30px">
                Каталог
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col
                cols="12"
                sm="6"
                v-for="(item, index) in items"
                :key="index"
                class="d-flex"
            >
              <v-card
                  min-height="140px"
                  min-width="100%"
                  class="item"
                  rounded="12"
                  elevation="3"
                  @click="push(item)"
                  style="white-space: break-spaces; padding-right: 5px; cursor: pointer;"
              >
                <v-row style="width: 100%; height: 100%">
                  <v-col cols="6">
                    <div style="padding: 5px; height: 100%;   width: 100%;">
                      <v-img
                          alt="Товар"
                          @click="push(item)"
                          :src="item.demonstrativeImg"
                          height="100%"
                          min-width="100%"
                      ></v-img>
                    </div>

                  </v-col>
                  <v-col cols="6">
                    <div
                        class="item-text"
                        style="font-size: 1.1em; margin-top: 10px; white-space: break-spaces"
                    >
                      <a v-html="item.name"/>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col>
          <ContactsMe></ContactsMe>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style>
.item:hover {
  background-color: #fff;
}

.item-text {
  font-size: 1.1rem;
  line-height: 1.1rem
}

.item-text:hover {
  color: #313ae3;
}
</style>

<script>
import ContactsMe from "@/components/ContactsMe.vue";

import {defineComponent} from "vue";

export default defineComponent({
  name: "CatalogCol",
  props: {
    items: Array,
  },
  components: {
    ContactsMe,
  },
  mounted() {
    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 1);
  },
  methods: {
    push(item) {
      this.$router.push({name: `product`, params: {name: item.id, product: item}})
    }
  },
  data() {
    return {
      activeItem: null,
      dialogItem: false,
    }
  }
})
</script>
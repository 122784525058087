<script>
import {defineComponent} from 'vue'
import CatalogCol from "@/components/CatalogCol.vue";

export default defineComponent({
  name: "WareHouseView, DeliveryView",
  mounted() {
    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 1);
  },
  components: {CatalogCol},
  props: {
    items: Array,
  }
})
</script>

<template>
  <div style="background-color: #eeeeee">
    <v-container>
      <v-row>
        <v-col cols="12" lg="9" xs="12">
          <v-card  rounded="0" color="white">
            <v-card-title style="font-size: 30px;border-left: #03A9F4 4px solid">Наличие на складе</v-card-title>
            <v-card-text style="font-size: 18px; margin-top: 20px; margin-left: 2px">
              По вопросам наличия звоните по телефону: +7 (3513) 25-72-83</v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <CatalogCol :items="items"></CatalogCol>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>

</style>
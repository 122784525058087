<script>
import {defineComponent} from 'vue'
import CatalogCol from "@/components/CatalogCol.vue";

export default defineComponent({
  name: "AboutUsView",
  mounted() {
    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 1);
  },
  components: {CatalogCol},
  props: {
    items: Array,
  }
})
</script>

<template>
  <div style="background-color: #eeeeee">
    <v-container>
      <v-row>
        <v-col cols="12" lg="9" xs="12">
          <v-card color="white"  rounded="0">
            <v-card-title style="font-size: 30px;border-left: #2196F3 4px solid">О нас</v-card-title>
            <v-card-text style="font-size: 18px; margin-top: 20px; margin-left: 2px">
              <p>Производство электрической распределительной и регулирующей аппаратуры. </p>
              <p style="margin-top: 10px">Разработка и производство систем управления для спецтехники.</p>
            </v-card-text>
<!--            <v-row style="margin: 15px">-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn to="/labour-protection" color="blue-darken-4" variant="outlined">Охрана труда</v-btn>-->
<!--            </v-row>-->
          </v-card>
        </v-col>
        <v-col>
          <CatalogCol :items="items"></CatalogCol>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>

</style>
<script>
import {defineComponent} from 'vue'
import CatalogCol from "@/components/CatalogCol.vue";

export default defineComponent({
  name: "ContactsView",
  components: {CatalogCol},
  mounted() {
    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 1);
  },
  props: {
    items: Array,
  }
})
</script>

<template>
  <div style="background-color: #eeeeee">
    <v-container>
      <v-row>
        <v-col cols="12" lg="9" xs="12">
          <v-card  rounded="0">
            <v-card  rounded="0" color="white">
              <v-card-title style="font-size: 30px;border-left: #2196F3 4px solid">Контакты</v-card-title>
            </v-card>
            <v-card  rounded="0" color="white">
              <v-card-title style="margin-left: 4px">Реквизиты</v-card-title>
              <v-card-text
                  style="margin-left: 4px;
                          margin-top: 10px;
                          font-size: 1rem;
                          font-weight: 400;">
                <p>
                  <b>Полное название:</b> Общество с ограниченной ответственностью «СпецЭл»
                  <br>
                  <b>ОГРН:</b> 1177456020653
                  <br>
                  <b>ИНН:</b> 7415097240
                  <br>
                  <b>КПП:</b> 741501001
                  <br>
                  <b>р/с:</b> 40702810990210000264
                  <br>
                  ПАО «Челябинвестбанк» г. Челябинск
                  <br>
                  <b>БИК:</b> 047501779
                  <br>
                  <b>к/с:</b> 30101810400000000779
                  <v-divider color="white"></v-divider>
                  <br>
                  <b>Директор:</b> Васильев Евгений Петрович
                  <br>
                  <br>
                  <b>Главный бухгалтер:</b> Трифонова Анастасия Владимировна
                  <v-divider color="white"></v-divider>
                  <br>
                  <b>Адрес:</b>
                  <br>
                  456300, Челябинская область, город Миасс, ул. Объездная дорога, дом 2/10, офис 203
                  <br><br>
                  <b>Почтовый адрес:</b>
                  <br>
                  456318, Челябинская обл., г. Миасс, а/я 1072
                  <br><br>
                  Юридический адрес совпадает с физическим.

                </p>
              </v-card-text>
              <v-divider color="white"></v-divider>
            </v-card>
            <v-list color="white" style="background-color: white">
              <v-list-item
                  style="background-color: white; color: black" prepend-icon="mdi-phone"
                  title="+7 (3513) 25-72-83"
              ></v-list-item>

              <v-divider inset color="black"></v-divider>

              <v-list-item
                  style=" color: black;"
                  prepend-icon="mdi-email"
              >
                <v-list-item-action>
                  <a style="text-decoration: none" href="mailto:info@nppse.com"><p style="color: black">
                    info@nppse.com</p></a>
                </v-list-item-action>
              </v-list-item>

              <v-divider inset color="black"></v-divider>

              <v-list-item
                  style="background-color: white; color: black"
                  prepend-icon="mdi-map-marker"
                  title="456300, Челябинская область, город Миасс, ул. Объездная дорога, дом 2/10, офис 203
"
              ></v-list-item>

              <v-divider inset color="black"></v-divider>

            </v-list>
            <v-card class="hidden-sm-and-down" style="margin: 15px;background-color: #eeeeee">
              <iframe src="https://yandex.ru/map-widget/v1/-/CCSpRBMg" height="400" width="100%"
                      frameborder="0"></iframe>
            </v-card>
            <v-card  class="hidden-md-and-up" style="margin: 15px;background-color: #eeeeee">
              <iframe src="https://yandex.ru/map-widget/v1/-/CCSpRBMg" height="400" width="100%"
                      ></iframe>
            </v-card>
          </v-card>
        </v-col>
        <v-col>
          <CatalogCol :items="items"></CatalogCol>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>

</style>
<template>
  <v-toolbar color="#eeeeee">
    <v-container style="margin-bottom: -20px">
      <v-btn-toggle class="hidden-md-and-down"
                    fixed density="comfortable"
                    style="color: #0D47A1;"
                    variant="text"
                    color="black"
                    v-model="toggle">
        <v-btn to="/" rounded="0">Главная</v-btn>
        <v-btn to="/products" rounded="0">Каталог товаров</v-btn>
        <v-btn to="/warehouse" rounded="0">Наличие на складе</v-btn>
        <v-btn to="/delivery" rounded="0">Доставка и оплата</v-btn>
        <v-btn to="/contacts" rounded="0">Контакты</v-btn>
        <v-btn to="/about-us" rounded="0">О нас</v-btn>

      </v-btn-toggle>

      <div class="hidden-lg-and-up" style="text-align: center; ">
        <v-menu :location="'bottom center'">
          <template v-slot:activator="{ props }">
            <v-btn variant="text" color="blue-darken-4" prepend-icon="mdi-menu" v-bind="props">Меню</v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-btn block color="#0D47A1" variant="tonal" router-link to="/" rounded="0">Главная</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn block color="#0D47A1" variant="tonal" router-link to="/products" rounded="0">Каталог товаров
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn block color="#0D47A1" variant="tonal" router-link to="/warehouse" rounded="0">Наличие на складе
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn block color="#0D47A1" variant="tonal" router-link to="/delivery" rounded="0">Доставка и оплата
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn block color="#0D47A1" variant="tonal" router-link to="/contacts" rounded="0">Контакты</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn block color="#0D47A1" variant="tonal" router-link to="/about-us" rounded="0">О нас</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <hr style="border-color: #2196F3">
    </v-container>


  </v-toolbar>

  <hr style="border: 5px #6ab5f1;">

</template>

<script>
export default ({
  data() {
    return {
      act: false,
      toggle: null,
      menu: [
        {icon: "home", title: "Link A"},
        {icon: "info", title: "Link B"},
        {icon: "warning", title: "Link C"}
      ]
    }
  }
})
</script>

<style>

</style>
<template>
  <div style="background-color: #eeeeee">
    <v-container>
      <v-row>
        <div class="hidden-lg-and-up" style="text-align: center; ">
          <v-btn router-link to="/products"
                 variant="tonal"
                 icon="mdi-chevron-left"
                 style="position:fixed;
                 background-color: #0D47A1;
                                            left:5%;
                                            top: 92%;
                                            z-index: 10"
                 color="white"></v-btn>
          </div>
        <v-col>
          <v-card>
            <v-card-title class="text-h5" style=" font-size: 70vw; white-space: break-spaces" v-text="this.item.name" >
            </v-card-title>
            <v-carousel
                :alt="this.item.name"
                color="blue"
                hide-delimiter-background
            >
              <template v-slot:prev="{ props }">
                <v-btn
                    icon="mdi-chevron-left"
                    variant="elevated"
                    @click="handlePrevClick(props)"
                ></v-btn>
              </template>
              <template v-slot:next="{ props }">
                <v-btn
                    icon="mdi-chevron-right"
                    variant="elevated"
                    @click="handleNextClick(props)"
                ></v-btn>
              </template>
              <v-carousel-item
                  v-for="(photo,index) in item.imgs"
                  :key="index"
                  style="margin-bottom: 10px;"
              >
                <v-img :src="photo.src"
                       contain
                       class="zoomable"
                       @click="toggleZoom(index)"
                       @mousemove="moveImage"
                       :style="{
                    transform: `scale(${zoomedImage === index ? 2.5 : 1})`,
                    'transform-origin': zoomedImage === index ? `${zoomPosition.x}px ${zoomPosition.y}px` : 'center',
                    width: zoomedImage === index ? '200%' : '100%',
                    height: zoomedImage === index ? '200%' : '100%',
                    'transition-duration': zoomedImage === index ? '0.2s' : '0s',
                  }"
                ></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>

          <v-card style="margin-top: 10px" v-if="this.item.information !== 'Описание'">
            <v-card-title class="text-h5">Описание</v-card-title>
            <v-card-text>
              <div style="font-size: 18px" v-html="this.item.information"></div>
            </v-card-text>
          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  components: {},
  beforeMount() {
    this.item = this.items.filter(a => a.id === this.$route.params.name)[0]
    document.title = this.item.name
  },
  mounted() {
    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 1);
  },
  props: {
    product: null,
  },
  data() {
    return {
      zoomedImage: null,
      zoomPosition: {x: 0, y: 0},
      item: {},
      items: [
        {
          id: 'blok-ba-001',
          name: 'Блок электроники БЭ-001',
          price: '0.0р',
          information: 'Управляет тяговым инвертором ИТ-2 в трамвайных вагонах и другим оборудованием.</br>' +
              '</br><p class="text-h6">Модификации</p>' +
              'БЭ-001 – с двумя платами управления ИТ2.МУ5 + плата опторазвязки\n</br>' +
              'БЭ-001-01 – с одной платой управления ИТ2.МУ5\n</br>' +
              'БЭ-001-02 – с двумя платами управления ИТ2.МУ5\n</br>',
          imgs: [
            {src: '/photos/blok/Blok.png'},
            {src: '/photos/blok/Blok_two.png'},
            {src: '/photos/blok/Blok_three.png'},
            {src: '/photos/blok/Blok_four.png'},
            {src: '/photos/blok/Blok_five.png'},
          ],
          demonstrativeImg: '/photos/blok/Blok_preview.png'
        },
        {
          id: 'blok-byd-4a',
          name: 'Модуль управления БУД-4А',
          price: '0.0р',
          information: 'Управление приводом дверей в трамвайных вагонах.',
          imgs: [
            {src: '/photos/bud_4a/Bud-4A.png'},
            {src: '/photos/bud_4a/Bud-4A_2.png'},
          ],
          demonstrativeImg: '/photos/bud_4a/Bud-4A_preview.png'
        },
        {
          id: 'blok-byd-24',
          name: 'Модуль управления БУД-24.МУ',
          price: '0.0р',
          information: 'Используются в трамвайных вагонах.'
              + '</br>' +
              '</br><p class="text-h6">Модификации</p>' +
              'БУД-24.МУ2-02 – управление приводом дверей\n</br>' +
              'БУД-24.МУ3-02 – управление приводом тормозов\n</br>' +
              'БУД-24.МУ1 – управление приводом пантографа (устаревшие трамваи)\n</br>' +
              'БУД-24.МУ7 – управление приводом дверей (устаревшие трамваи)\n</br>' +
              'БУД-24.МУ8 – управление приводом рабочего и стояночного тормоза (устаревшие трамваи)\n</br>' +
              'БУД-24.МУ9 – управление приводом рабочего и стояночного тормоза (устаревшие трамваи)\n</br>',
          imgs: [
            {src: '/photos/bud_24/bud_24.png'},
            {src: '/photos/bud_24/bud-24_2.png'},
          ],
          demonstrativeImg: '/photos/bud_24/bud_24_preview.png',
          docs: '/docs/БУД24.pdf'
        },
        {
          id: 'pm-28',
          name: 'Пульт маневровый ПМ-28.01',
          price: '0.0р',
          information: 'Пульт маневровый ПМ предназначен для формирования управляющих сигналов, необходимых при перегоне вагона трамвая.\n' +
              'Используется для установки на вагонах.',
          imgs: [
            {src: '/photos/buttons/PM 29_1.png'},
            {src: '/photos/buttons/PM 29_2.png'},
            {src: '/photos/buttons/PM 29_3.png'},
          ],
          demonstrativeImg: '/photos/buttons/PM 29_1_preview.png'
        },
        {
          id: 'pc-6',
          name: 'Панель с кнопками ПК-6.01',
          price: '0.0р',
          information: 'Предназначен для формирования сигналов управления трамвайных вагонов.',
          imgs: [
            {src: '/photos/buttons_6_01/PM.png'},
            {src: '/photos/buttons_6_01/PM2.png'},
            {src: '/photos/buttons_6_01/PM3.png'},
            {src: '/photos/buttons_6_01/PM4.png'},
            {src: '/photos/buttons_6_01/PM5.png'},
          ],
          demonstrativeImg: '/photos/buttons_6_01/PM_preview.png'
        },
        {
          //pult 1
          id: 'pult-1',
          name: 'Панель с кнопками ПК-6.02',
          price: '0.0р',
          information: 'Предназначен для формирования сигналов управления трамвайных вагонов.',
          imgs: [
            {src: '/photos/buttons_6_02/pult_1_2.png'},
            {src: '/photos/buttons_6_02/pult_1.png'},
            {src: '/photos/buttons_6_02/pult_1_3.png'},
            {src: '/photos/buttons_6_02/pult_1_4.png'},
          ],
          demonstrativeImg: '/photos/buttons_6_02/pult_1_2_preview.png'
        },
        {
          //pult 2
          id: 'pult-2',
          name: 'Панель с кнопками ПК-6.02 ХХ2',
          price: '0.0р',
          information: 'Предназначен для формирования сигналов управления трамвайных вагонов.',
          imgs: [
            {src: '/photos/buttons_6_02_xx2/pult_2_2.png'},
            {src: '/photos/buttons_6_02_xx2/pult_2.png'},
            {src: '/photos/buttons_6_02_xx2/pult_2_3.png'},
            {src: '/photos/buttons_6_02_xx2/pult_2_4.png'},
          ],
          demonstrativeImg: '/photos/buttons_6_02_xx2/pult_2_2_preview.png'
        },
        {
          id: 'bi-01',
          name: 'Блок измерений БИ-01',
          price: '0.0р',
          information: 'Основание с модулем питания ИТ2.МП200.001 – для формирования питающих напряжений.' +
              '</br></br><p class="text-h6">Модификации</p>' +
              'Блок измерений БИ-01-01\n' +
              '</br>Дополнительно установлен Датчик напряжения ДН-2 V3\n',

          imgs: [
            {src: '/photos/bi_01/MP.png'},
            {src: '/photos/bi_01/MP_2.png'},
            {src: '/photos/bi_01/MP_3.png'},
          ],
          demonstrativeImg: '/photos/bi_01/MP_preview.png'
        },
        {
          id: 'pav-20-01',
          name: 'Панель автоматических выключателей ПАВ-20-01',
          price: '0.0р',
          information: 'Панель автоматических выключателей ПАВ-20-01 предназначена для защиты питающих цепей.\n' +
              'Используется для установки на вагонах.',
          imgs: [
            {src: '/photos/pav/PAV.png'},
            {src: '/photos/pav/PAV 2.png'},
            {src: '/photos/pav/PAV 3.png'},
          ],
          demonstrativeImg: '/photos/pav/PAV_preview.png',
          docs: '/docs/ПАВ.pdf'
        },
        {
          //Три монитора
          id: 'pvi_8',
          name: 'Панель визуализации информации ПВИ-8',
          price: '0.0р',
          information:
              'Блок управления ПВИ предназначен для выполнения алгоритма программы, установленной в блок, вывода графической информации на внешний монитор, приём сигналов управления и формирования управляющих сигналов.\n' +
              'Используется для установки на трамвайных вагонах и спецтехнике.',
          imgs: [
            {src: '/photos/three_monitors/02.png'},
            {src: '/photos/three_monitors/03.png'},
            {src: '/photos/three_monitors/04.png'}
          ],
          demonstrativeImg: '/photos/three_monitors/02_preview.png'
        },
        {
          //Три монитора
          id: 'pvi_9',
          name: 'Панель визуализации информации ПВИ-9',
          price: '0.0р',
          information:
              'Блок управления ПВИ предназначен для выполнения алгоритма программы, установленной в блок, вывода графической информации на внешний монитор, приём сигналов управления и формирования управляющих сигналов.\n' +
              'Используется для установки на трамвайных вагонах и спец. технике.',
          imgs: [
            {src: '/photos/three_monitors/01.png'},
            {src: '/photos/three_monitors/01_1.png'},
            {src: '/photos/three_monitors/06.png'},
            {src: '/photos/three_monitors/07.png'},
            {src: '/photos/three_monitors/08.png'},
            {src: '/photos/three_monitors/09.png'},
          ],
          demonstrativeImg: '/photos/three_monitors/01_1_preview.png'
        },
        {
          //Два монитора
          id: 'pvm-4',
          name: 'Монитор видеокамер ПВМ-4',
          price: '0.0р',
          information: 'Монитор видеокамер ПВМ предназначен для отображения видеокамер трамвайного вагона, а также другой служебной информации о вагоне.',
          imgs: [
            {src: '/photos/two_monitors/mon 3.png'},
            {src: '/photos/two_monitors/mon 4.png'},
            {src: '/photos/two_monitors/mon 5.png'},
          ],
          demonstrativeImg: '/photos/two_monitors/mon 3_preview.png'
        },
        {
          //podlok 5
          id: 'podlok-kp-03',
          name: 'Комплект подлокотников в сборе КП-03',
          price: '0.0р',
          information: 'Комплекты подлокотников предназначены для формирования управляющих сигналов трамвайного вагона и устанавливаются на кресло водителя.' +
              '<br/><br/>' +
              '<p class="text-h6">Модификации</p>' +
              '' +
              'КП-13 – на разъём выведены дополнительные сигналы',
          imgs: [
            {src: '/photos/podlok_kp_03/podlok_5_5.png'},
            {src: '/photos/podlok_kp_03/podlok_5.png'},
            {src: '/photos/podlok_kp_03/podlok_5_2.png'},
            {src: '/photos/podlok_kp_03/podlok_5_3.png'},
            {src: '/photos/podlok_kp_03/podlok_5_4.png'},
            {src: '/photos/podlok_kp_03/podlok_6_4.png'},
            {src: '/photos/podlok_kp_03/podlok_6.png'},
            {src: '/photos/podlok_kp_03/podlok_6_2.png'},
            {src: '/photos/podlok_kp_03/podlok_6_3.png'},
            {src: '/photos/podlok_kp_03/podlok_6_5.png'},
          ],
          demonstrativeImg: '/photos/podlok_kp_03/podlok_5_5_preview.png'
        },
        {
          id: 'podlok-kp-04',
          name: 'Комплект подлокотников в сборе КП-04',
          price: '0.0р',
          information: 'Комплекты подлокотников предназначены для формирования управляющих сигналов трамвайного вагона и устанавливаются на кресло водителя.',
          imgs: [
            {src: '/photos/podlok_kp_04/podlok_1_3.png'},
            {src: '/photos/podlok_kp_04/podlok_1.png'},
            {src: '/photos/podlok_kp_04/podlok_1_2.png'},
            {src: '/photos/podlok_kp_04/podlok_1_4.png'},
            {src: '/photos/podlok_kp_04/podlok_1_5.png'},
            {src: '/photos/podlok_kp_04/podlok_1_6.png'},
            {src: '/photos/podlok_kp_04/podlok_2.png'},
            {src: '/photos/podlok_kp_04/podlok_2_2.png'},
            {src: '/photos/podlok_kp_04/podlok_2_3.png'},
            {src: '/photos/podlok_kp_04/podlok_2_4.png'},
          ],
          demonstrativeImg: '/photos/podlok_kp_04/podlok_1_3_preview.png'
        },
        {
          //podlok 3
          id: 'podlok-kp-05',
          name: 'Комплект подлокотников в сборе КП-05',
          price: '0.0р',
          information: 'Комплекты подлокотников предназначены для формирования управляющих сигналов трамвайного вагона и устанавливаются на кресло водителя.',
          imgs: [
            {src: '/photos/podlok_kp_05/podlok_3_3.png'},
            {src: '/photos/podlok_kp_05/podlok_3.png'},
            {src: '/photos/podlok_kp_05/podlok_3_2.png'},
            {src: '/photos/podlok_kp_05/podlok_3_4.png'},
            {src: '/photos/podlok_kp_05/podlok_3_5.png'},
            {src: '/photos/podlok_kp_05/podlok_4_4.png'},
            {src: '/photos/podlok_kp_05/podlok_4.png'},
            {src: '/photos/podlok_kp_05/podlok_4_2.png'},
            {src: '/photos/podlok_kp_05/podlok_4_3.png'},
            {src: '/photos/podlok_kp_05/podlok_4_5.png'},
          ],
          demonstrativeImg: '/photos/podlok_kp_05/podlok_3_3_preview.png'
        },


      ]
    }
  },
  methods: {
    moveImage(event) {
      if (this.zoomedImage !== null) {
        this.zoomPosition = {
          x: event.offsetX,
          y: event.offsetY,
        };
      }
    },
    toggleZoom(index) {
      this.zoomedImage = this.zoomedImage === index ? null : index;
      if (this.zoomedImage === index) {
        this.zoomPosition = {
          x: event.offsetX,
          y: event.offsetY,
        };
      }
    },
    resetZoom() {
      this.zoomedImage = null;
    },
    handlePrevClick(props) {
      this.resetZoom();
      props.onClick();
    },
    handleNextClick(props) {
      this.resetZoom();
      props.onClick();
    }
  }
})
</script>

<style>
.zoomable {
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}
</style>

<script>
import {defineComponent} from 'vue'
import CatalogCol from "@/components/CatalogCol.vue";

export default defineComponent({
  name: "MainView.vue",
  components: {CatalogCol},
  mounted() {
    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 1);
  },
  props: {
    items: Array,
  }
})
</script>

<template style="background-color: #eeeeee">
  <v-card color="#eeeeee" rounded="0" elevation="0">
    <v-container>
      <v-carousel color="white"
                  hide-delimiter-background
                  :interval="4000"
                  cycle
                  :show-arrows="false"
                  delimiter-icon="mdi-circle"
                  class="hidden-sm-and-down">
        <v-carousel-item cover width="100%%" src="../assets/main/carousel_first.jpg"></v-carousel-item>
        <v-carousel-item cover :key="2" src="../assets/main/carousel_second.jpg"></v-carousel-item>
        <v-carousel-item cover :key="3" src="../assets/main/carousel_third.jpg"></v-carousel-item>
      </v-carousel>

      <v-carousel color="blue"
                  class="hidden-md-and-up"
                  :interval="4000"
                  cycle
                  :show-arrows="false"
                  delimiter-icon="mdi-square">
        <v-carousel-item cover :key="1" alt="Трамвай" src="../assets/main/carousel_first_mobile.jpg"></v-carousel-item>
        <v-carousel-item cover :key="2" alt="Трамвай" src="../assets/main/carousel_second_mobile.jpg"></v-carousel-item>
        <v-carousel-item cover :key="3" alt="Трамвай" src="../assets/main/carousel_third_mobile.jpg"></v-carousel-item>
      </v-carousel>


      <hr style="border-color: #2196F3; margin-top: 20px">
      <v-row style="margin-top: 10px">
        <v-col cols="12" lg="9" xs="12">
          <v-card rounded="0" serve
                  height="70vh">
            <v-card-title style="font-size: 35px;"><b>ООО «СпецЭл»</b></v-card-title>
            <hr/>
            <v-card-text style="font-size: 18px; line-height: 24px" color="mv-5">
              <p>
                Основным видом деятельности нашей компании является производство специализированной, электрической,
                распределительной и регулирующей аппаратуры, применяемой в трамваях и троллейбусах для предприятий
                городского общественного транспорта. Наша компания успешно развивается на рынке с 2017 года и постоянно
                совершенствуется, осваивая современные технологии.
                <br/>
                <br/>
                Мы работаем на выгодных условиях и нацелены на долгосрочное сотрудничество, гарантируя:
                <v-list>
                  <v-list-item-group>
                    <v-list-item class="list-item-custom">- Качество продукции</v-list-item>
                    <v-list-item class="list-item-custom">- Своевременность отгрузок</v-list-item>
                    <v-list-item class="list-item-custom">- Моментальную обратную связь</v-list-item>
                  </v-list-item-group>
                </v-list>


                Поможем подобрать оптимальное решение для наших заказчиков.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <CatalogCol :items="items"></CatalogCol>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style scoped>
.list-item-custom {
  min-height: 24px !important;
}
</style>